<template>
  <div>
    <!-- 校区管理 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.distName" clearable placeholder="请输入校区名称" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form>

    <div class="content-wrap">
      <div class="btn-wrap cfx">
        <el-button type="success" size="small" icon="el-icon-plus" @click="handleForm(false)" v-if="OA.includes('jcxx:xqgl:add')">添加</el-button>
      </div>
      <div v-if="campusTotal==0" class="totalNull"><img src="../assets/images/nothing.svg" alt=""></div>
      <!--列表-->
      <paged-table
        v-if="campusTotal>0"
        :data="campusList"
        :total="campusTotal"
        :loading="tLoading"
        :isShowSelection="false"
        defaultSortProp="id"
        v-model="queryParams"
        @refresh="refresh"
      > 
        <el-table-column prop="extend.schoolName" show-overflow-tooltip label="学校名称"></el-table-column>
        <el-table-column prop="distName" show-overflow-tooltip label="校区名称"></el-table-column>
        <el-table-column prop="major" show-overflow-tooltip label="专业"></el-table-column>
        <el-table-column prop="address" show-overflow-tooltip label="地址"></el-table-column>
        <el-table-column show-overflow-tooltip label="经度">
          <template slot-scope="scope">
            <span>{{scope.row.longitude}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="纬度">
          <template slot-scope="scope">
            <span>{{scope.row.latitude}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="所在区域">
          <template slot-scope="scope">
            <span>{{ scope.row.extend.provinceName ? scope.row.extend.provinceName : '' }}</span> -
            <span>{{ scope.row.extend.cityName ? scope.row.extend.cityName : '' }}</span> -
            <span>{{ scope.row.extend.areaName ? scope.row.extend.areaName : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button size="mini" type="primary" @click="handleForm(true, scope.row,afterHandleForm)" icon="el-icon-edit" title="编辑" v-if="OA.includes('jcxx:xqgl:editor')"></el-button>
            <el-button size="mini" type="danger" @click="handleDelete(false,{id: scope.row.id})" icon="el-icon-delete" title="删除" v-if="OA.includes('jcxx:xqgl:del')"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>
    
    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑校区' : '添加校区'" :visible.sync="aVisible" width="700px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="所属学校：" prop="schoolId">
          <el-select clearable filterable v-model="form.schoolId" @change="schoolArea" :disabled="disable">
            <el-option v-for="item in schoolOptions" :label="item.schoolName" :value="item.id+''" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="校区名称：" prop="distName">
          <el-input v-model="form.distName" clearable placeholder=" 例如（西区）或（本部）"></el-input>
        </el-form-item>
        <el-form-item label="所在省：" prop="province">
          <el-select clearable filterable v-model="form.province" disabled>
            <el-option v-for="item in provinceOptions" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在市：" prop="city">
          <el-select clearable filterable v-model="form.city" disabled>
            <el-option v-for="item in cityOptions" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在区：" prop="area">
          <el-select clearable filterable v-model="form.area" >
            <el-option v-for="item in areaOptions" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经纬度：" prop="longitude">
          <el-input v-model="form.longitude" placeholder="经度" readonly style="width:200px"></el-input>
          <el-input v-model="form.latitude" placeholder="纬度" readonly style="width:200px; margin-left:30px"></el-input>
          <i class="el-icon-location" @click="cVisible = true" style="margin-left:30px; font-size:30px; color:#28B892; vertical-align:middle;"></i>
        </el-form-item>
        <el-form-item label="地址：" prop="address">
          <el-input v-model="form.address" clearable placeholder="请输入地址"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <!-- <el-dialog title="校区详情" :visible.sync="bVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="学校Id：" prop="schoolId">
          <span v-text="details.schoolId"></span>
        </el-form-item>
        <el-form-item label="学校名称：" prop="schoolId">
          <span v-text="details.extend.schoolName"></span>
        </el-form-item>
        <el-form-item label="校区名称：" prop="distName">
          <span v-text="details.distName"></span>
        </el-form-item>
        <el-form-item label="地址：" prop="address">
          <span v-text="details.address"></span>
        </el-form-item>
        <el-form-item label="经度：" prop="longitude">
          <span v-text="details.longitude"></span>
        </el-form-item>
        <el-form-item label="纬度：" prop="latitude">
          <span v-text="details.latitude"></span>
        </el-form-item>
        <el-form-item label="所在区域：" prop="latitude">
          <span>{{ details.extend.provinceName ? details.extend.provinceName : '' }}</span> -
          <span>{{ details.extend.cityName ? details.extend.cityName : '' }}</span> -
          <span>{{ details.extend.areaName ? details.extend.areaName : '' }}</span>
        </el-form-item>
      </el-form>
    </el-dialog> -->

    <el-dialog title="经纬度选择" :visible.sync="cVisible" fullscreen :close-on-click-modal="false">
				<div class="map-container" v-if="cVisible">
<<<<<<< HEAD
					<maps :lng="parseFloat(form.longitude)" :lat="parseFloat(form.latitude)" @finish="setLngLat"></maps>
=======
					<maps :lng="parseFloat(form.longitude)" :lat="parseFloat(form.latitude)" @finish="setLngLat(point,address)"></maps>
>>>>>>> 1c3fa98fdc2a0e4a394da1f6fd82dec9856adc4a
				</div>
			</el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
// import Maps from '@/components/common/Maps'
import Maps from '@/views/Amap'
import { log, mergeObject } from '@/common/utils'
export default {
  name:'campusManagement',
  components: {
    PagedTable,
    Maps
  },
  mixins: [pageMixin,provinces],
  data() {
    return {
      isditu: false,
      provinceValue: "",
      cityValue: "",
      regionValue: "",
      s_addr:"",
      s_house_number: "",
      s_latitude :"",
      s_longitude :"",

      point: [],
      address: '',
      queryParams: {
        distName: null
      },
      schoolOptions:[],
      areaOptions:[],
      form:{
        id: '',
        schoolId: '',
        distName:'',
        address:'',
        longitude: '',
        latitude: '',
        province:'',
        city:'',
        area:''
      },
      disable:false,
      formRules:{
        schoolId: [{ required: true, message: '请选择学校名称', trigger: 'blur' }],
        distName: [{ required: true, message: '请输入校区名称', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        province: [{ required: true, message: '请选择省份', trigger: 'blur' }],
        city: [{ required: true, message: '请选择市区', trigger: 'blur' }],
        area: [{ required: true, message: '请选择区域', trigger: 'blur' }],
        longitude: [{ required: true, message: '请输入经度', trigger: 'blur' }],
        latitude: [{ required: true, message: '请输入纬度', trigger: 'blur' }],
      },
      details:{
        schoolId: '',
        distName:'',
        address:'',
        longitude: '',
        latitude: '',
        extend:{}
      },
      pageApi:'membersCampusList',
      insertApi:'membersCampusAdd',
      editorApi:'membersCampusEdit',
      deleteApi:'membersCampusDelete'
    };
  },
  computed: {
    ...mapState(['OA']),
    ...mapState("basicCampus", ["campusList", "campusTotal"]),
  },
  methods: {
    ...mapActions("basicCampus",["membersCampusList", "membersCampusAdd", "membersCampusEdit", "membersCampusDelete",""]),
    ...mapActions("commonOptions", ["getSchoolCombo","dictGetDictFPid"]),
    async afterHandleForm(row){
      if(row.province) this.cityOptions = await this.commonDictGetDictFPid({pid: row.province})
      if(row.city) this.areaOptions = await this.commonDictGetDictFPid({pid: row.city})
    },
    change(e) {
      this.$forceUpdate()
    },
    async schoolArea(id) {
      const cityId = this.schoolOptions.find(v=>v.id==id).city
      console.log(cityId)
      const res = await this.dictGetDictFPid({pid:cityId})
      this.areaOptions = res
      this.form.province = this.schoolOptions.find(v=>v.id==id).province
      this.cityOptions = await this.commonDictGetDictFPid({pid: this.form.province})
      this.form.city = this.schoolOptions.find(v=>v.id==id).city
    },
    setLngLat(val){
      log(val)
      this.form.longitude = val[0]
      this.form.latitude = val[1]
      this.form.address = val[2]
      this.cVisible = false
    },
    cancel() {
      this.form.latitude = ''
      this.aVisible = false
    }
  },
  async mounted() {
     if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
    this.schoolOptions = await this.getSchoolCombo({})
    console.log(this.form)

    if(sessionStorage.getItem('schoolId')!=="null") {
      this.form.schoolId = sessionStorage.getItem('schoolId')
      console.log(this.form.schoolId)
      this.schoolArea(this.form.schoolId)
      this.disable = true
    } 
  }
};
</script>